<template>
  <v-container>
    <v-row class="mt-6">
      <v-col cols="12" md="6" offset-md="1">
        <v-card>
          <v-card-title primary-title> Informe de simulaciones </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <LabelComponent text="Fecha desde:" />
              <v-text-field
                v-model="fecha_desde"
                outlined
                prepend-icon="mdi-calendar-arrow-left"
                placeholder="dd/mm/yyyy"
                :rules="rules"
                v-mask="dateMask"
              ></v-text-field>

              <LabelComponent text="Fecha hasta:" />
              <v-text-field
                v-model="fecha_hasta"
                outlined
                prepend-icon="mdi-calendar-arrow-right"
                placeholder="dd/mm/yyyy"
                :rules="rules"
                v-mask="dateMask"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="text-none mx-4"
              width="140"
              @click="descargar"
              rounded
              :loading="loading"
            >
              <v-icon left>mdi-download</v-icon>
              Descargar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LabelComponent from "@/components/Inputs/Label.vue";
import { mapActions } from "vuex";

import { dateMask } from "@/helpers/mask.js";
import { validarFecha } from "@/store/resources/validate.js";

export default {
  name: "InformesSimulacionPage",
  components: {
    LabelComponent,
  },

  data() {
    return {
      loading: false,
      fecha_desde: "",
      fecha_hasta: "",
      rules: [
        (v) => !!v || "Campo de fecha es requerido.",
        (v) => validarFecha(v) || "Fecha no válida.",
      ],
    };
  },
  methods: {
    ...mapActions("Informes", ["getSimulaciones"]),

    descargar() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.getSimulaciones({
          fecha_desde: this.convertirFormatoFecha(this.fecha_desde),
          fecha_hasta: this.convertirFormatoFecha(this.fecha_hasta),
          usuario: this.user.usuario,
          canal_venta: this.channel?.id ?? "",
          convenio: "",
          output: "xlsx",
        })
          .then((res) => {
            // Crea un enlace para la descarga
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(res.data);
            link.download = `Simulaciones_${(
              this.channel?.description ?? "canal"
            ).replace(/ /g, "_")}-${this.convertirFormatoFecha(
              this.fecha_desde
            )}_${this.convertirFormatoFecha(this.fecha_hasta)}.xlsx`;

            // Añade el enlace al cuerpo y haz clic en él
            document.body.appendChild(link);
            link.click();

            // Limpia después de la descarga
            document.body.removeChild(link);

            this.loading = false;
          })
          .catch(() => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: "Ha ocurrido un error al intentar generar el informe",
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    convertirFormatoFecha(fechaString) {
      // Dividir la cadena en día, mes y año
      const partes = fechaString.split("/");

      // Crear un objeto Date con las partes
      const fecha = new Date(partes[2], partes[1] - 1, partes[0]);

      // Obtener las partes de la fecha en el nuevo formato
      const nuevoFormato = `${fecha.getFullYear()}-${this.formatoDosDigitos(
        fecha.getMonth() + 1
      )}-${this.formatoDosDigitos(fecha.getDate())}`;

      return nuevoFormato;
    },
    formatoDosDigitos(numero) {
      return numero < 10 ? `0${numero}` : numero;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    dateMask: () => dateMask,
    channel() {
      return this.$store.getters.getChannelSelected;
    },
  },
};
</script>
